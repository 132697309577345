import React from "react";
import { Flex, Stack, Image } from "@chakra-ui/core";
import { useRestaurant } from "../../context/restaurant-context";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/auth-context";
import {
  KarriFormInput,
  KarriFormError
} from "../../components/KarriFormInput";
import { GreyKarriButton } from "../../components/KarriButton";

interface IFormData {
  username: string;
  password: string;
}

const LogIn: React.FC = () => {
  const { restaurant } = useRestaurant();
  const { signIn } = useAuth();
  const { handleSubmit, register, errors } = useForm<IFormData>();

  const onLogin = async (values: IFormData) => {
    await signIn(values.username, values.password);
  };

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Stack
        as="form"
        onSubmit={handleSubmit(onLogin)}
        justifyContent="center"
        spacing={5}
        minW="400px"
      >
        <Image
          src={restaurant.logo}
          objectFit="contain"
          size="100px"
          margin="0 auto"
          alt={restaurant.name + " Logo"}
        />
        <KarriFormInput
          id="username"
          name="username"
          type="email"
          isRequired
          label="Email"
          ref={register({
            required: "Required"
          })}
          error={
            errors.username?.message && (
              <KarriFormError mt="-8px">
                {errors.username.message}
              </KarriFormError>
            )
          }
        />
        <KarriFormInput
          id="password"
          type="password"
          name="password"
          isRequired
          label="Password"
          ref={register({
            required: "Required"
          })}
          error={
            errors.password?.message && (
              <KarriFormError mt="-8px">
                {errors.password.message}
              </KarriFormError>
            )
          }
        />
        <GreyKarriButton type="submit" mt={4}>
          Login
        </GreyKarriButton>
      </Stack>
    </Flex>
  );
};

export default LogIn;
