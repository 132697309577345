import { Box, Heading, Icon, Image, Stack, Text } from "@chakra-ui/core";
import React from "react";
import { useRestaurant } from "../../context/restaurant-context";
import { useUser } from "../../context/user-context";

const CheckoutSuccess: React.FC = () => {
  const { restaurant } = useRestaurant();
  const { user } = useUser();

  return (
    <Stack align="center" spacing={7} p={8} flexGrow={1} position="relative">
      <Image src={restaurant.logo} size="150px" />
      <Stack isInline spacing={3} align="center" justify="center" pl={4}>
        <Heading fontWeight={500} color="#333333" fontSize="24px">
          Order Confirmed
        </Heading>
        <Icon
          name="check"
          color="#2CC90C"
          size="30px"
          p="5px"
          borderRadius="50%"
          borderColor="#2CC90C"
          borderWidth="2px"
        />
      </Stack>
      <Stack textAlign="center" spacing={4} mt={5}>
        <Text>Thanks for your order!</Text>
        <Text>
          We will send you an SMS to keep you updated on your food's progress.
        </Text>
        <Text>
          Enjoy your meal{" "}
          <span role="img" aria-label="Thumbs Up">
            👍
          </span>
        </Text>
      </Stack>
      <Stack
        color="#333333"
        alignSelf="flex-start"
        position="absolute"
        bottom="80px"
        spacing={6}
      >
        <Box>
          <Text color="#4F4F4F">Order for</Text>
          <Text fontWeight={500}>{user.name}</Text>
          <Text fontWeight={500}>+61{user.phoneNumber}</Text>
        </Box>
        {/* @TODO: enable for delivery */}
        {/* <Box>
          <Text color="#4F4F4F">Address</Text>
          <Text fontWeight={500}>{user.address}</Text>
        </Box> */}
      </Stack>
    </Stack>
  );
};

export default CheckoutSuccess;
