import { Box, Divider, Flex, Heading, Stack, Text } from "@chakra-ui/core";
import React from "react";
import { useOrders } from "../context/order-context";
import { IOrder, OrderStatus } from "../types";
import { formatMoney } from "../utils/formatMoney";
import { orderStatusDisplayMap } from "../utils/orderUtils";
import { DashboardOrderItem } from "./DashboardOrderItem";
import { KarriButton } from "./KarriButton";

/**
 * Gets the next status in the lifecycle of an order.
 *
 * For example, if an order is PENDING, it's next available status would be PREPARING.
 * Returns `undefined` if there is no next status (eg if the order is complete).
 */
const nextOrderStatus = (order: IOrder) => {
  const { status } = order.data;
  if (status === OrderStatus.PENDING) {
    return OrderStatus.PREPARING;
  } else if (status === OrderStatus.PREPARING && order.data.type === "Pickup") {
    return OrderStatus.PICKUP;
  } else if (status === OrderStatus.PICKUP) {
    return OrderStatus.COMPLETE;
  } else if (status === OrderStatus.COMPLETE) {
    return undefined;
  }
  return undefined;
};

export const DashboardOrderSummary: React.FC = () => {
  const { currentOrder, updateOrderStatus } = useOrders();

  if (currentOrder === undefined) {
    return null;
  }

  const nextStatus = nextOrderStatus(currentOrder);

  return (
    <Flex direction="column" overflow="hidden">
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        p={5}
        borderBottomColor="#E0E0E0"
        borderBottomWidth="1px"
        borderStyle="solid"
      >
        <Heading size="xs">{currentOrder.id.slice(0, 8)}</Heading>
        {nextStatus && (
          <KarriButton
            color="white"
            backgroundColor="#2F80ED"
            onClick={() => updateOrderStatus(nextStatus)}
            px="45px"
            pt="13px"
            pb="30px"
            h="48px"
          >
            Mark as {orderStatusDisplayMap.get(nextStatus)}
          </KarriButton>
        )}
      </Flex>
      <Flex direction="column" p={5} overflowY="scroll">
        <Box mb={5}>
          <Text fontWeight={500} color="#1E2B51">
            {currentOrder.data.contactName || "-"}
          </Text>
          <Text color="#4F4F4F">{currentOrder.data.address || "-"}</Text>
          <Text color="#4F4F4F">{currentOrder.data.contactNumber || "-"}</Text>
          <Text color="#4F4F4F">{currentOrder.data.contactEmail || "-"}</Text>
        </Box>
        <Stack maxW="400px" spacing={5}>
          {currentOrder.data.items.map((item, i) => (
            <Flex direction="row">
              <DashboardOrderItem item={item} />
            </Flex>
          ))}
          <Divider />
          {/* totals go here */}
          <Flex justify="space-between" align="center">
            <Text fontSize="sm" color="#4F4F4F">
              Subtotal
            </Text>
            <Text fontSize="sm" color="#4F4F4F">
              {formatMoney(currentOrder.data.price)}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm" color="#4F4F4F">
              Delivery Fee
            </Text>
            <Text fontSize="sm" color="#4F4F4F">
              {formatMoney(currentOrder.data.price)}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            <Text fontSize="sm" color="#1E2B51">
              Total
            </Text>
            <Text fontSize="sm" color="#1E2B51">
              {formatMoney(currentOrder.data.price)}
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
};
