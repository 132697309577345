/**
 * Turns a number like 10 into $10.00
 * @param value the value to format
 */
export const formatMoney = (value: number) =>
  value.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 2
  });
