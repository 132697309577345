import React from "react";
import { Flex, IconButton, Stack, FlexProps } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";

const CheckoutLayout: React.FC<FlexProps> = ({ children, ...props }) => {
  const history = useHistory();
  return (
    <Flex position="relative" flexGrow={1} overflow="hidden">
      <IconButton
        onClick={() => history.push("/menu")}
        icon="close"
        aria-label="Back to menu"
        variant="ghost"
        position="absolute"
        top={3}
        right={3}
      />
      <Flex
        direction="column"
        flexGrow={1}
        overflow="hidden"
        justify="space-between"
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const CheckoutBottom: React.FC = ({ children }) => {
  return (
    <Stack
      spacing={3}
      alignSelf="flex-end"
      p={5}
      w="100%"
      backgroundColor="#F2F2F2"
    >
      {children}
    </Stack>
  );
};

export default CheckoutLayout;
