import { useEffect, useState } from "react";
import { useCart } from "../context/cart-context";
import { fb } from "./firebase";
import { IMenuItemInCart, IMenuItem } from "../types";

const mapCartItemsToFirebaseAnalytics = (items: IMenuItemInCart[]) =>
  items.map(i => ({
    id: i.id,
    name: i.title,
    category: i.category,
    price: i.price,
    quantity: i.quantity
  }));

const mapItemsToFirebaseAnalytics = (items: IMenuItem[]) =>
  items.map(i => ({
    id: i.id,
    name: i.title,
    category: i.category,
    price: i.price
  }));

export const useAnalyticsBeginCheckout = () => {
  // prevent sending multiple 'begin checkout' events if the cart total changes
  const [sent, setSent] = useState<boolean>(false);
  const { items, totalCartValue } = useCart();
  useEffect(() => {
    if (!sent) {
      fb.analytics().logEvent("begin_checkout", {
        items: mapCartItemsToFirebaseAnalytics(items),
        value: totalCartValue
      });
      setSent(true);
    }
  }, [items, totalCartValue, sent]);
};

export const analyticsViewItem = (item: IMenuItem) => {
  fb.analytics().logEvent("view_item", {
    items: mapItemsToFirebaseAnalytics([item])
  });
};

export const analyticsViewCart = ({
  items,
  value
}: {
  items: IMenuItemInCart[];
  value: number;
}) => {
  fb.analytics().logEvent("view_cart", {
    items: mapCartItemsToFirebaseAnalytics(items),
    value
  });
};

export const analyticsViewItemList = ({
  items,
  itemListName
}: {
  items: IMenuItem[];
  itemListName: string;
}) => {
  fb.analytics().logEvent("view_item_list", {
    items: mapItemsToFirebaseAnalytics(items),
    item_list_name: itemListName
  });
};

export const analyticsRemoveFromCart = ({
  items,
  value
}: {
  items: IMenuItemInCart[];
  value: number;
}) => {
  fb.analytics().logEvent("view_cart", {
    items: mapCartItemsToFirebaseAnalytics(items),
    value
  });
};

export const analyticsPurchase = ({
  items,
  value,
  transactionId
}: {
  items: IMenuItemInCart[];
  value: number;
  transactionId?: string;
}) => {
  fb.analytics().logEvent("view_cart", {
    items: mapCartItemsToFirebaseAnalytics(items),
    value,
    transaction_id: transactionId
  });
};
