import React from "react";
import {
  Flex,
  Text,
  Button,
  Stack,
  Box,
  ButtonGroup,
  MenuButton,
  Menu,
  MenuList,
  MenuItem
} from "@chakra-ui/core";
import { useRestaurant } from "../context/restaurant-context";
import { isDev } from "../utils/development";
import { createDummyOrder } from "../utils/devModeCreateDummyOrder";
import { useAuth } from "../context/auth-context";

export const OrderDashboardLayout: React.FC = ({ children }) => {
  const { restaurant } = useRestaurant();
  const { signOut } = useAuth();

  return (
    <Flex w="100vw" h="100vh" direction="column" overflow="hidden">
      <Flex
        flexShrink={0}
        as="nav"
        borderBottomColor="#E0E0E0"
        borderBottomWidth="1px"
        borderStyle="solid"
        w="100%"
        justify="space-between"
        align="center"
        h="60px"
        px={4}
        overflow="hidden"
      >
        <Stack isInline align="center" spacing={6} overflow="hidden">
          <Box
            flexShrink={0}
            background={
              restaurant.logo ? `url("${restaurant.logo}")` : "#dadada"
            }
            size="40px"
            backgroundSize="cover"
          />
          <Text color="#333">Orders</Text>
        </Stack>
        <ButtonGroup>
          {isDev && (
            <Button onClick={() => createDummyOrder(restaurant.id)}>
              (Dev Mode) Fake Order
            </Button>
          )}
          <Menu>
            <MenuButton
              as={Button}
              // @ts-ignore
              variant="ghost"
              // @ts-ignore
              rightIcon={"chevron-down"}
            >
              Account
            </MenuButton>
            <MenuList>
              <MenuItem onClick={signOut}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Flex>
      {children}
    </Flex>
  );
};
