import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GhostKarriButton,
  GreenKarriButton,
} from "../../components/KarriButton";
import { StripeCard } from "../../components/StripeCard";
import { useCart } from "../../context/cart-context";
import { useRestaurant } from "../../context/restaurant-context";
import { useUser } from "../../context/user-context";
import CheckoutLayout, { CheckoutBottom } from "../../layouts/CheckoutLayout";
import { formatMoney } from "../../utils/formatMoney";
import { analyticsPurchase } from "../../utils/analytics";

const CheckoutPayment: React.FC = () => {
  const history = useHistory();
  const stripe = useStripe();
  const { restaurant } = useRestaurant();
  const { placeOrder, items, totalCartValue } = useCart();
  const elements = useElements();
  const { user } = useUser();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const chargeCard = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(undefined);
    console.log("charging card");

    if (restaurant.demo === true) {
      // place a demo order without a stripe token
      await placeOrder(user, undefined);
      history.push("/menu/confirmed");
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.log("no stripe");
      return;
    }

    const card = elements.getElement(CardElement);

    if (card) {
      const result = await stripe.createToken(card);

      if (result.error) {
        console.log(result.error.message);
        setErrorMessage(result.error.message);
        setIsSubmitting(false);
      } else if (result.token?.id === undefined) {
        setErrorMessage(
          "Something went wrong while contacting our payment servers. Please try again later."
        );
        setIsSubmitting(false);
      } else {
        const response = await placeOrder(user, result.token.id);
        analyticsPurchase({
          items,
          value: totalCartValue,
          transactionId: response?.chargeId,
        });
        history.push("/menu/confirmed");
      }
    }
  };

  const showStripe = restaurant.demo !== true;

  return (
    <CheckoutLayout as="form" onSubmit={chargeCard}>
      <Flex direction="column" pt={5}>
        <Image
          objectFit="contain"
          src={restaurant.logo}
          size="120px"
          alignSelf="center"
          alt={restaurant.name + " logo"}
        />
        <Stack spacing={4} p={5} overflowY="scroll">
          <Heading size="md">My Order</Heading>
          <Stack spacing={2} shouldWrapChildren>
            {items.map((item) => (
              <Flex direction="row" justify="space-between" align="center">
                <Box fontSize="sm">
                  <Text fontWeight={500} color="#333333">
                    {item.title}
                  </Text>
                  <Text color="#4F4F4F">{item.description}</Text>
                  <Text fontWeight={500} color="#333333">
                    {formatMoney(item.price * item.quantity)}
                  </Text>
                </Box>
                <Flex
                  rounded="full"
                  align="center"
                  justify="center"
                  borderColor="gray"
                  borderWidth="1px"
                  borderStyle="solid"
                  size="32px"
                >
                  {item.quantity}
                </Flex>
              </Flex>
            ))}
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={2} p={5}>
          <Flex justify="space-between">
            <Text>Subtotal</Text>
            <Text>{formatMoney(totalCartValue)}</Text>
          </Flex>
          <Flex justify="space-between" align="center" fontWeight="bold">
            <Text>Total</Text>
            <Text>{formatMoney(totalCartValue)}</Text>
          </Flex>
        </Stack>
        <Stack spacing={2} p={5}>
          <Stack isInline align="center">
            <Heading size="md">Payment details</Heading>
            <Icon name="lock" />
          </Stack>
          {showStripe ? (
            <StripeCard />
          ) : (
            <Text>
              Usually customers will enter their credit card details here. This
              is a demo restaurant, so payment details aren't required.
            </Text>
          )}
          {errorMessage && <Text>{errorMessage}</Text>}
        </Stack>
      </Flex>
      <CheckoutBottom>
        <SimpleGrid spacing={3} gridTemplateColumns="1fr 2fr">
          <GhostKarriButton size="lg" onClick={() => history.push("/menu")}>
            Back
          </GhostKarriButton>
          <GreenKarriButton size="lg" type="submit" isLoading={isSubmitting}>
            Place Order
          </GreenKarriButton>
        </SimpleGrid>
      </CheckoutBottom>
    </CheckoutLayout>
  );
};

export default CheckoutPayment;
