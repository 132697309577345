import { fb } from "./firebase";
import { OrderStatus, IOrderData } from "../types";

export async function createDummyOrder(restaurantId: string) {
  const newOrder: IOrderData = {
    address: "address",
    contactNumber: "contact number 09876543",
    contactEmail: "test@email.com",
    contactName: "Guy",
    items: [
      {
        description: "White toast",
        id: "EX1",
        image:
          "https://img.taste.com.au/hR1Ei0iV/w643-h428-cfill-q90/taste/2016/11/summer-salad-sandwich-92212-1.jpeg",
        price: 10,
        quantity: 1,
        title: "Sandwich",
        category: "Sandwiches"
      }
    ],
    status: OrderStatus.PENDING,
    type: "Pickup",
    price: 10,
    stripeReference: "xxx",
    restaurantRef: fb.firestore().doc("restaurants/" + restaurantId) as any,
    createdAt: new Date()
  };

  await fb
    .firestore()
    .collection("orders")
    .add(newOrder);
}
