import { Flex, Heading, Text } from "@chakra-ui/core";
import React from "react";
import { IMenuItemInCart } from "../types";
import { formatMoney } from "../utils/formatMoney";

interface Props {
  item: IMenuItemInCart;
}

export const DashboardOrderItem: React.FC<Props> = ({
  item: { id, title, description, image, price, quantity }
}) => {
  return (
    <Flex w="100%" justify="space-between" align="center">
      <Flex w="100%">
        <Heading size="sm" color="#2F80ED" minW="32px">
          {quantity} x
        </Heading>
        <Heading color="#1E2B51" size="sm">
          {title}
        </Heading>
      </Flex>
      <Text color="#333333" fontSize="sm">
        {formatMoney(price)}
      </Text>
    </Flex>
  );
};
