import React, { useContext, useState } from "react";
import { IMenuItem, IMenuItemInCart, IOrderRequest, IUser } from "../types";
import { useRestaurant } from "./restaurant-context";

interface CartContextProps {
  items: IMenuItemInCart[];
  hasItemsInCart: boolean;
  totalCartValue: number;
  totalCartQuantity: number;
  addToCart: (item: IMenuItem, quantity: number) => void;
  updateQuantity: (item: IMenuItem, newQuantity: number) => void;
  placeOrder: (
    user: Partial<IUser>,
    stripeReference?: string
  ) => Promise<{ chargeId: string } | undefined>;
  clearCart: () => void;
}

export const CartContext = React.createContext<CartContextProps>({
  items: [],
  hasItemsInCart: false,
  totalCartValue: 0,
  totalCartQuantity: 0,
  addToCart: () => {},
  updateQuantity: () => {},
  placeOrder: async () => undefined,
  clearCart: () => {},
});

export const CartProvider: React.FC = ({ children }) => {
  const { restaurant } = useRestaurant();
  const [items, setItems] = useState<IMenuItemInCart[]>([]);

  const addToCart = (item: IMenuItem, quantity: number) => {
    if (quantity > 0) {
      const existingItemIndex = items.findIndex((i) => i.id === item.id);
      if (existingItemIndex !== -1) {
        const existingItem = items[existingItemIndex];

        setItems([
          ...items.slice(0, existingItemIndex),
          { ...existingItem, quantity: existingItem.quantity + quantity },
          ...items.slice(existingItemIndex + 1),
        ]);
      } else {
        setItems([...items, { ...item, quantity }]);
      }
    }
  };

  const updateQuantity = (item: IMenuItem, newQuantity: number) => {
    const existingItemIndex = items.findIndex((i) => i.id === item.id);
    if (existingItemIndex !== -1) {
      const existingItem = items[existingItemIndex];

      if (newQuantity <= 0) {
        // remove item from cart
        setItems([
          ...items.slice(0, existingItemIndex),
          ...items.slice(existingItemIndex + 1),
        ]);
      } else {
        // update the quantity of the item
        setItems([
          ...items.slice(0, existingItemIndex),
          { ...existingItem, quantity: newQuantity },
          ...items.slice(existingItemIndex + 1),
        ]);
      }
    }
  };

  const placeOrder = async (user: Partial<IUser>, stripeToken?: string) => {
    console.log("order placed!", { items });

    if (restaurant.demo !== true && stripeToken === undefined) {
      throw new Error("Stripe token was not provided");
    }

    const request: IOrderRequest = {
      items,
      totalOrderValue: totalCartValue,
      customer: user,
      stripeToken,
      restaurantId: restaurant.id,
    };

    const result = await fetch("/api/createOrder", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify(request),
    });
    return (await result.json()) as { chargeId: string };
  };

  const clearCart = () => {
    setItems([]);
  };

  const hasItemsInCart = items.length > 0;
  const totalCartValue = items.reduce((a, b) => a + b.price * b.quantity, 0);
  const totalCartQuantity = items.reduce((a, b) => a + b.quantity, 0);

  return (
    <CartContext.Provider
      value={{
        items,
        hasItemsInCart,
        totalCartValue,
        addToCart,
        updateQuantity,
        placeOrder,
        clearCart,
        totalCartQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within an CartProvider");
  }
  return context;
};
