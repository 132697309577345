import React, { useState } from "react";
import { IUser } from "../types";
import { createCtx } from "./createCtx";

interface UserContextProps {
  user: Partial<IUser>;
  setUser: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  userInfoComplete: boolean;
}

const [useUser, UserContextProvider] = createCtx<UserContextProps>();

interface Props {
  /** for testing purposes */
  initialUserDetails?: Partial<IUser>;
}

const UserProvider: React.FC<Props> = ({ children, initialUserDetails }) => {
  const [user, setUser] = useState<Partial<IUser>>(initialUserDetails || {});

  const userInfoComplete =
    user.address !== undefined &&
    user.email !== undefined &&
    user.name !== undefined &&
    user.phoneNumber !== undefined;
  // need to change this back
  // user.postCode !== undefined;

  return (
    <UserContextProvider
      value={{
        user,
        setUser,
        userInfoComplete
      }}
    >
      {children}
    </UserContextProvider>
  );
};

export { UserProvider, useUser };
