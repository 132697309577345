import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/core";
import React from "react";
import { IMenuItem } from "../types";
import { formatMoney } from "../utils/formatMoney";

interface Props {
  item: IMenuItem;
}

export const KarriMenuItem: React.FC<Props> = ({ item }) => {
  const { title, description, image, price } = item;
  return (
    <Flex h="100%" w="100%" alignItems="space-between">
      <Stack spacing={1} p={1} flexGrow={1}>
        <Heading size="sm">{title}</Heading>
        <Text fontSize="sm" color="#616161">
          {description}
        </Text>
        <Text fontSize="sm">{formatMoney(price)}</Text>
      </Stack>
      <Box
        flexShrink={0}
        borderRadius="3px"
        background={image ? `url("${image}")` : "#dadada"}
        w="80px"
        h="80px"
        backgroundSize="cover"
      />
    </Flex>
  );
};
