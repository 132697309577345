import React, { useState } from "react";
import { fb } from "../utils/firebase";
import { createCtx } from "./createCtx";

interface AuthContextProps {
  user?: fb.User;
  signOut: () => Promise<void>;
  signIn: (username: string, password: string) => Promise<void>;
}

const [useAuth, AuthContextProvider] = createCtx<AuthContextProps>();

const AuthProvider: React.FC = ({ children }) => {
  const currentUser = fb.auth().currentUser || undefined;
  const [user, setUser] = useState<fb.User | undefined>(currentUser);

  const signIn = async (username: string, password: string) => {
    await fb.auth().setPersistence(fb.auth.Auth.Persistence.LOCAL);
    const result = await fb
      .auth()
      .signInWithEmailAndPassword(username, password);
    setUser(result.user || undefined);
  };

  const signOut = async () => {
    await fb.auth().signOut();
    setUser(undefined);
  };

  return (
    <AuthContextProvider value={{ user, signIn, signOut }}>
      {children}
    </AuthContextProvider>
  );
};

export { useAuth, AuthProvider };
