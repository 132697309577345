import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StripeProvider } from "./context/stripe";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { karriTheme } from "./theme";

ReactDOM.render(
  <StripeProvider>
    <ThemeProvider theme={karriTheme}>
      <CSSReset />
      <App />
    </ThemeProvider>
  </StripeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
