import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  RadioButtonGroup,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { KarriBigRadioButton } from "../../components/KarriBigRadioButton";
import {
  GhostKarriButton,
  GreyKarriButton
} from "../../components/KarriButton";
import {
  KarriFormError,
  KarriFormInput
} from "../../components/KarriFormInput";
import { useCart } from "../../context/cart-context";
import { useUser } from "../../context/user-context";
import CheckoutLayout, { CheckoutBottom } from "../../layouts/CheckoutLayout";
import { IUser } from "../../types";
import { useAnalyticsBeginCheckout } from "../../utils/analytics";
import { formatMoney } from "../../utils/formatMoney";

const CheckoutEnterDetails: React.FC = () => {
  const { totalCartValue } = useCart();
  const history = useHistory();
  const { user, setUser } = useUser();
  const { handleSubmit, register, errors } = useForm<IUser>({
    defaultValues: user
  });

  useAnalyticsBeginCheckout();

  const onSubmit = (values: IUser) => {
    setUser(values);
    history.push("/menu/checkout/payment");
  };

  return (
    <CheckoutLayout as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} p={5} pt={12} overflowY="scroll">
        <Heading size="sm">Pickup or delivery?</Heading>
        <RadioButtonGroup defaultValue="pickup" dir="column" spacing={2}>
          <KarriBigRadioButton value="pickup" w="100%">
            Pickup
          </KarriBigRadioButton>
          <Text
            textTransform="uppercase"
            textAlign="center"
            color="#828282"
            fontSize="12px"
          >
            Or
          </Text>
          <KarriBigRadioButton value="delivery" w="100%" isDisabled>
            Delivery (coming soon)
          </KarriBigRadioButton>
        </RadioButtonGroup>
        {/* @TODO: enable for delivery */}
        {/* <Flex
            direction="row"
            justify="space-between"
            align="center"
            fontSize="sm"
          >
            <Text>Delivery Fee</Text>
            <Text>$5.00</Text>
          </Flex> */}
        <Stack>
          <KarriFormInput
            label="Full name"
            isRequired
            id="name"
            name="name"
            ref={register({
              required: "Required"
            })}
            error={
              errors.name?.message && (
                <KarriFormError mt="-8px">{errors.name.message}</KarriFormError>
              )
            }
          />
          {/* @TODO: enable for delivery */}
          {/* <KarriFormInput
              label="Delivery address"
              isRequired
              id="address"
              name="address"
              ref={register({
                required: "Required"
              })}
              error={
                errors.address?.message && (
                  <KarriFormError mt="-8px">
                    {errors.address.message}
                  </KarriFormError>
                )
              }
            />
            <SimpleGrid spacing={6} gridTemplateColumns="5fr 3fr">
              <KarriFormInput
                label="Suburb"
                isRequired
                id="suburb"
                name="suburb"
                ref={register({
                  required: "Required"
                })}
                error={
                  errors.suburb?.message && (
                    <KarriFormError>{errors.suburb.message}</KarriFormError>
                  )
                }
              />
              <KarriFormInput
                label="Postcode"
                isRequired
                id="postcode"
                name="postCode"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[\d]{4}/i,
                    message: "invalid postcode"
                  }
                })}
                error={
                  errors.postCode?.message && (
                    <KarriFormError>{errors.postCode.message}</KarriFormError>
                  )
                }
              />
            </SimpleGrid> */}
          <KarriFormInput
            label="Email"
            isRequired
            type="email"
            id="email"
            name="email"
            ref={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address"
              }
            })}
            error={
              errors.email?.message && (
                <KarriFormError mt="-8px">
                  {errors.email.message}
                </KarriFormError>
              )
            }
          />
          <FormControl aria-required="true">
            <FormLabel
              htmlFor="mobile"
              fontSize="sm"
              fontWeight="normal"
              color="#4F4F4F"
            >
              Mobile
            </FormLabel>
            <InputGroup>
              <InputLeftAddon children="+61" />
              <Input
                type="text"
                pattern="[0-9]*"
                id="phoneNumber"
                name="phoneNumber"
                ref={register({
                  pattern: {
                    value: /^[\d]{9}/i,
                    message: "invalid mobile number"
                  },
                  required: "Required"
                })}
              />
            </InputGroup>
            {errors.phoneNumber?.message && (
              <Text color="red.500" fontSize="sm">
                {errors.phoneNumber.message}
              </Text>
            )}
          </FormControl>
        </Stack>
      </Stack>
      <CheckoutBottom>
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          fontSize="sm"
        >
          <Text>Total</Text>
          <Text>{formatMoney(totalCartValue)}</Text>
        </Flex>
        <SimpleGrid spacing={3} gridTemplateColumns="1fr 2fr">
          <GhostKarriButton size="lg" onClick={() => history.push("/menu")}>
            Back
          </GhostKarriButton>
          <GreyKarriButton size="lg" type="submit">
            Next
          </GreyKarriButton>
        </SimpleGrid>
      </CheckoutBottom>
    </CheckoutLayout>
  );
};

export default CheckoutEnterDetails;
