import React from "react";
import { RadioProps, Icon } from "@chakra-ui/core";
import { KarriButton } from "./KarriButton";

export const KarriBigRadioButton: React.FC<RadioProps> = React.forwardRef(
  (props, ref) => {
    const { isChecked, isDisabled, value, children, ...rest } = props;
    return (
      <KarriButton
        ref={ref}
        color={isChecked ? "white" : "#4F4F4F"}
        flexGrow={1}
        backgroundColor={isChecked ? "#4F4F4F" : "#F2F2F2"}
        aria-checked={isChecked}
        role="radio"
        isDisabled={isDisabled}
        _hover={{
          backgroundColor: isChecked ? "#3d3f40" : undefined
        }}
        position="relative"
        {...rest}
      >
        {isChecked && (
          <Icon
            name="check-circle"
            color="white"
            position="absolute"
            left={4}
          />
        )}

        {children}
      </KarriButton>
    );
  }
);
