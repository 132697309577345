import React from "react";
import { IDatedOpeningHours } from "../types";
import {
  applyDatesToOpeningHours,
  getCurrentHours
} from "../utils/openingHoursUtils";
import { createCtx } from "./createCtx";
import { useRestaurant } from "./restaurant-context";

interface OpeningHoursContextProps {
  isOpen: boolean;
  todaysOpeningHours: IDatedOpeningHours[];
}

const [useOpeningHours, OpeningHoursContextProvider] = createCtx<
  OpeningHoursContextProps
>();

const OpeningHoursProvider: React.FC = ({ children }) => {
  const { restaurant } = useRestaurant();

  const time = new Date();

  const todaysOpeningHoursString = restaurant.openingHours.filter(
    hrs => hrs.day === time.getDay()
  );

  const todaysOpeningHours = applyDatesToOpeningHours(
    todaysOpeningHoursString,
    time
  );
  const currentOpeningHours = getCurrentHours(todaysOpeningHours, time);
  const isOpen = currentOpeningHours.length > 0;

  return (
    <OpeningHoursContextProvider
      value={{
        todaysOpeningHours,
        isOpen
      }}
    >
      {children}
    </OpeningHoursContextProvider>
  );
};

/** Mock Provider for testing/storybook purposes */
const MockOpeningHoursProvider = OpeningHoursContextProvider;

export { OpeningHoursProvider, useOpeningHours, MockOpeningHoursProvider };
