import { OrderStatus } from "../types";

/** Maps the order status enum to a nicer string display format.
 * This also allows us to preserve the typing while we use it.
 */
export const orderStatusDisplayMap = new Map<OrderStatus, string>([
  [OrderStatus.PENDING, "New"],
  [OrderStatus.PREPARING, "Preparing"],
  [OrderStatus.PICKUP, "Pickup"],

  // [OrderStatus.DELIVERING, "Delivering"],
  [OrderStatus.COMPLETE, "Complete"]
]);
