import { Flex, Spinner, Text, Stack } from "@chakra-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { IOrder, OrderStatus } from "../types";
import { fb } from "../utils/firebase";
import { createCtx } from "./createCtx";
import { useRestaurant } from "./restaurant-context";
import { GreyKarriButton } from "../components/KarriButton";
import { useAuth } from "./auth-context";

interface OrdersContextProps {
  orders: IOrder[];
  loading: boolean;
  newOrder?: IOrder;
  error: boolean;
  setFilterStatus: React.Dispatch<React.SetStateAction<OrderStatus>>;
  filterStatus: OrderStatus;
  filteredOrders: IOrder[];
  currentOrder: IOrder | undefined;
  setCurrentOrder: React.Dispatch<React.SetStateAction<IOrder | undefined>>;
  updateOrderStatus: (newStatus: OrderStatus) => Promise<void>;
}

const [useOrders, OrdersContextProvider] = createCtx<OrdersContextProps>();

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const OrdersProvider: React.FC = ({ children }) => {
  const { restaurant } = useRestaurant();
  const [filterStatus, setFilterStatus] = useState<OrderStatus>(
    OrderStatus.PENDING
  );
  const [newOrder, setNewOrder] = useState<IOrder>();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const prevOrders = usePrevious(orders);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [filteredOrders, setFilteredOrders] = useState<IOrder[]>([]);
  const [currentOrder, setCurrentOrder] = useState<IOrder>();
  const { signOut } = useAuth();

  const updateOrderStatus = async (newStatus: OrderStatus) => {
    if (currentOrder !== undefined) {
      await fb
        .firestore()
        .collection("orders")
        .doc(currentOrder!.id)
        .update({ status: newStatus });
      setCurrentOrder(undefined);
    }
  };

  // update `newOrder` if there we are done loading and the orders array has changed from what it used to be
  // assumes that the new order has been appended to the end of the array
  useEffect(() => {
    if (!loading && prevOrders) {
      if (orders.length > prevOrders.length) {
        setNewOrder(orders[orders.length - 1]);
      }
    }
  }, [loading, orders, prevOrders]);

  //reset current order when changing tabs
  useEffect(() => {
    setCurrentOrder(undefined);
  }, [filterStatus]);

  useEffect(() => {
    setFilteredOrders(
      orders.filter(order => order.data.status === filterStatus)
    );
  }, [filterStatus, orders]);

  useEffect(() => {
    const restaurantRef = fb
      .firestore()
      .collection("restaurants")
      .doc(restaurant.id);

    const unsubscribe = fb
      .firestore()
      .collection("orders")
      .where("restaurantRef", "==", restaurantRef)
      .onSnapshot({
        next: snapshot => {
          const updatedOrders = snapshot.docs.map(doc => {
            const data = doc.data();
            const createdAt = data.createdAt.toDate();
            return {
              data: { ...data, createdAt },
              id: doc.id
            };
          }) as IOrder[];

          setOrders(updatedOrders);
          setLoading(false);
        },
        error: () => setError(true)
      });

    return unsubscribe;
  }, [restaurant.id]);

  if (orders === undefined) {
    return (
      <Flex align="center" justify="center" w="100vw" h="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return (
      <Stack h="100vh" w="100vw" justify="center" align="center" spacing={3}>
        <Text textAlign="center">
          There was an issue fetching orders :( <br />
          You may not have permission to access this restaurant. Please contact
          Karri Support if the issue persists.
        </Text>
        <GreyKarriButton onClick={signOut}>Logout</GreyKarriButton>
      </Stack>
    );
  }

  return (
    <OrdersContextProvider
      value={{
        orders,
        loading,
        error,
        setFilterStatus,
        filteredOrders,
        filterStatus,
        currentOrder,
        setCurrentOrder,
        updateOrderStatus,
        newOrder
      }}
    >
      {children}
    </OrdersContextProvider>
  );
};

export { OrdersProvider, useOrders };
