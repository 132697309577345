import { Flex } from "@chakra-ui/core";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/auth-context";
import { CartProvider } from "./context/cart-context";
import { OpeningHoursProvider } from "./context/opening-hours-context";
import { OrdersProvider } from "./context/order-context";
import { RestaurantProvider } from "./context/restaurant-context";
import { SubdomainProvider } from "./context/subdomain-context";
import { UserProvider } from "./context/user-context";
import CheckoutEnterDetails from "./routes/customer/CheckoutEnterDetails";
import CheckoutPayment from "./routes/customer/CheckoutPayment";
import CheckoutSuccess from "./routes/customer/CheckoutSuccess";
import MenuItemDetail from "./routes/customer/MenuItemDetail";
import MenuItemsList from "./routes/customer/MenuItemsList";
import MenuItemUpdateCart from "./routes/customer/MenuItemUpdateCart";
import LogIn from "./routes/orderDashboard/LogIn";
import OrderDashboard from "./routes/orderDashboard/OrderDashboard";
import { useScreenHeight } from "./utils/useScreenHeight";

const App: React.FC = () => {
  return (
    <SubdomainProvider>
      <RestaurantProvider>
        <Router>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/menu" />
            </Route>
            <Route path="/menu">
              <MenuRoutes />
            </Route>
            <Route path="/orders">
              <AuthProvider>
                <DashboardRoutes />
              </AuthProvider>
            </Route>
          </Switch>
        </Router>
      </RestaurantProvider>
    </SubdomainProvider>
  );
};

const MenuRoutes: React.FC = () => {
  const height = useScreenHeight();
  return (
    <OpeningHoursProvider>
      <UserProvider>
        <CartProvider>
          <Flex minW="100vw" h={height} overflow="hidden">
            <Switch>
              <Route path="/menu" exact>
                <MenuItemsList />
              </Route>
              <Route path="/menu/checkout/details">
                <CheckoutEnterDetails />
              </Route>
              <Route path="/menu/checkout/payment">
                <CheckoutPayment />
              </Route>
              <Route path="/menu/confirmed">
                <CheckoutSuccess />
              </Route>
              <Route path="/menu/:itemId" exact>
                <MenuItemDetail />
              </Route>
              <Route path="/menu/update/:itemId">
                <MenuItemUpdateCart />
              </Route>
            </Switch>
          </Flex>
        </CartProvider>
      </UserProvider>
    </OpeningHoursProvider>
  );
};

const DashboardRoutes: React.FC = () => {
  const { user } = useAuth();

  if (user === undefined) {
    return <LogIn />;
  }

  return (
    <Route path="/orders">
      <OrdersProvider>
        <OrderDashboard />
      </OrdersProvider>
    </Route>
  );
};

export default App;
