import {
  BoxProps,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Text
} from "@chakra-ui/core";
import React from "react";

interface Props extends InputProps<HTMLInputElement> {
  label: string;
  error?: React.ReactNode;
}

export const KarriFormInput: React.FC<Props> = React.forwardRef(
  (props: Props, ref: React.Ref<any>) => {
    const { label, isRequired, id, error, ...rest } = props;
    return (
      <FormControl aria-required={isRequired ? "true" : undefined}>
        <FormLabel
          htmlFor={id}
          fontSize="sm"
          fontWeight="normal"
          color="#4F4F4F"
        >
          {label}
        </FormLabel>
        <Input mb={0} ref={ref} id={id} {...rest} />
        {error}
      </FormControl>
    );
  }
);

export const KarriFormError: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Text color="red.500" fontSize="sm" {...props}>
      {children}
    </Text>
  );
};
