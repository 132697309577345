import React from "react";
import { useOrders } from "../context/order-context";
import { IOrder } from "../types";
import { Flex, Text, Box } from "@chakra-ui/core";
import { format } from "date-fns";

export const DashboardOrderList: React.FC = () => {
  const { filteredOrders, currentOrder, setCurrentOrder } = useOrders();

  return (
    <Flex direction="column" flexGrow={0}>
      {filteredOrders.map(order => (
        <OrderItem
          key={order.id}
          order={order}
          active={currentOrder?.id === order.id}
          onClick={() => setCurrentOrder(order)}
        />
      ))}
    </Flex>
  );
};

interface OrderItemProps {
  order: IOrder;
  active: boolean;
  onClick?: () => void;
}

const OrderItem: React.FC<OrderItemProps> = ({ order, active, onClick }) => {
  return (
    <Flex
      as="button"
      p={3}
      bg={active ? "#F9FAFB" : undefined}
      onClick={onClick}
    >
      <Box textAlign="left" w="30%">
        <Text fontWeight={500} color="#1E2B51">
          {order.id.slice(0, 8)}
        </Text>
        <Text color="#4F4F4F">
          {format(order.data.createdAt, "h:mm a").toLowerCase()}
        </Text>
      </Box>
      <Flex>
        <Box textAlign="left">
          <Text fontWeight={500} color="#1E2B51">
            {order.data.contactName}
          </Text>
          <Text color="#4F4F4F">{order.data.type}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};
