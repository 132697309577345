import { IOpeningHours, IDatedOpeningHours } from "../types";
import { isWithinInterval, parse, format } from "date-fns";

export const printTodaysOpeningHours = (
  openingHours: IDatedOpeningHours[]
): string => {
  if (openingHours.length === 0) {
    return "Closed";
  }
  return openingHours
    .map(hrs => `${formatTime(hrs.from)} - ${formatTime(hrs.to)}`)
    .join(", ");
};

const formatTime = (time: Date) => format(time, "hh:mm aa");

const parseTime = (timeString: string, baseDate: Date): Date =>
  parse(timeString, "HH:mm", baseDate);

/**
 * Parses the opening hours strings and applies a date to them specified by `time`.
 * @param openingHours the opening hours to transform
 * @param time the day
 */
export const applyDatesToOpeningHours = (
  openingHours: IOpeningHours[],
  time: Date
): IDatedOpeningHours[] => {
  return openingHours.map(hrs => ({
    from: parseTime(hrs.from, time),
    to: parseTime(hrs.to, time)
  }));
};

/**
 * Returns the current opening hours if the restaurant is open, or an empty array if its closed
 * @param openingHours the array of opening hours for the restaurant
 * @param time the time to check if the restaurant is open at
 */
export const getCurrentHours = (
  openingHours: IDatedOpeningHours[],
  time: Date
): IDatedOpeningHours[] => {
  return openingHours.filter(hrs =>
    isWithinInterval(time, { start: hrs.from, end: hrs.to })
  );
};
