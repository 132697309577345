import {
  Alert,
  AlertIcon,
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure
} from "@chakra-ui/core";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GreyKarriButton, KarriButton } from "../../components/KarriButton";
import { KarriCartDrawer } from "../../components/KarriCartDrawer";
import { KarriMenuItem } from "../../components/KarriMenuItem";
import { KarriRestaurantCard } from "../../components/KarriRestaurantCard";
import { useCart } from "../../context/cart-context";
import { useOpeningHours } from "../../context/opening-hours-context";
import { useRestaurant } from "../../context/restaurant-context";
import { formatMoney } from "../../utils/formatMoney";
import { ModalDeliveryFeatureRequest } from "../../components/ModalDeliveryFeatureRequest";
import { fb } from "../../utils/firebase";
import {
  analyticsViewCart,
  analyticsViewItemList
} from "../../utils/analytics";

const MenuItemsList: React.FC = () => {
  const { menu, restaurant } = useRestaurant();
  const { isOpen: restaurantIsOpen } = useOpeningHours();
  const {
    hasItemsInCart,
    totalCartValue,
    totalCartQuantity,
    items: cartItems
  } = useCart();
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const {
    isOpen: deliveryModalIsOpen,
    onOpen: onOpenDeliveryModal,
    onClose: onCloseDeliveryModal
  } = useDisclosure(false);
  const btnRef = React.useRef<HTMLElement | null>(null);
  const [menuFilter, setMenuFilter] = useState<string>(
    restaurant.menuCategories[0]
  );

  const handleTabsChange = (index: any) => {
    setMenuFilter(restaurant.menuCategories[index]);
  };

  const handleOpenDeliveryModal = () => {
    fb.analytics().logEvent("delivery_modal_opened");
    onOpenDeliveryModal();
  };

  const filteredItems = menu.filter(item => item.category === menuFilter);

  useEffect(() => {
    analyticsViewItemList({
      items: filteredItems,
      itemListName: menuFilter
    });
  }, [filteredItems, menuFilter]);

  useEffect(() => {
    if (isOpen) {
      analyticsViewCart({ items: cartItems, value: totalCartValue });
    }
  }, [isOpen, cartItems, totalCartValue]);

  const canViewCart = hasItemsInCart && restaurantIsOpen;

  return (
    <Flex flexGrow={1} direction="column" position="relative">
      <Box p={4}>
        <Stack spacing={4}>
          {restaurantIsOpen ? (
            <ButtonGroup spacing={2}>
              <GreyKarriButton rounded="full" size="sm">
                Pickup
              </GreyKarriButton>
              <KarriButton
                rounded="full"
                size="sm"
                onClick={handleOpenDeliveryModal}
              >
                Delivery
              </KarriButton>
            </ButtonGroup> // @TODO swap back to <PostCodeCheck /> for delivery
          ) : (
            <Alert status="warning">
              <AlertIcon />
              We're currently not open, but you can still browse our menu
              online.
            </Alert>
          )}
          <KarriRestaurantCard />
          <Tabs
            variantColor="black"
            isFitted={true}
            onChange={handleTabsChange}
          >
            <TabList>
              {restaurant.menuCategories.map(category => (
                <Tab key={category}>{category}</Tab>
              ))}
            </TabList>
          </Tabs>
          {filteredItems.map((item, i) => (
            <React.Fragment key={item.id}>
              <Link to={`/menu/${item.id}`}>
                <KarriMenuItem item={item} />
              </Link>
              {i < filteredItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      </Box>
      {canViewCart && (
        <Flex w="100%" pos="absolute" bottom={2} p={3}>
          <GreyKarriButton
            flexGrow={1}
            size="lg"
            ref={btnRef}
            onClick={onOpen}
            justifyContent="space-between"
          >
            <Text>View order</Text>
            <Stack isInline spacing={3}>
              <Flex
                color="#4F4F4F"
                backgroundColor="white"
                borderRadius="50%"
                align="center"
                justify="center"
                size="23px"
              >
                {totalCartQuantity}
              </Flex>
              <Text>{formatMoney(totalCartValue)}</Text>
            </Stack>
          </GreyKarriButton>
        </Flex>
      )}
      <KarriCartDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
      <ModalDeliveryFeatureRequest
        isOpen={deliveryModalIsOpen}
        onClose={onCloseDeliveryModal}
      />
    </Flex>
  );
};

export default MenuItemsList;
