import React from "react";
import { createCtx } from "./createCtx";
import { isDev } from "../utils/development";

interface SubdomainContextProps {
  subdomain: string;
}

const [useSubdomain, SubdomainContextProvider] = createCtx<
  SubdomainContextProps
>();

const getSubdomain = () => {
  if (isDev) {
    return "exponentcafe";
  }

  const domains = window.location.hostname.split(".");

  if (domains && domains.length > 1) {
    return domains[0];
  }
  return undefined;
};

const SubdomainProvider: React.FC = ({ children }) => {
  const subdomain = getSubdomain();

  if (subdomain === undefined) {
    return <div>error</div>;
  }

  return (
    <SubdomainContextProvider
      value={{
        subdomain
      }}
    >
      {children}
    </SubdomainContextProvider>
  );
};

export { SubdomainProvider, useSubdomain };
