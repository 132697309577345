import { Flex, Spinner, Text } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { IMenuItem, IRestaurantDetails } from "../types";
import { fb } from "../utils/firebase";
import { createCtx } from "./createCtx";
import { useSubdomain } from "./subdomain-context";

interface RestaurantContextProps {
  restaurant: IRestaurantDetails;
  menu: IMenuItem[];
}

const [useRestaurant, RestaurantContextProvider] = createCtx<
  RestaurantContextProps
>();

const RestaurantProvider: React.FC = ({ children }) => {
  const { subdomain } = useSubdomain();

  const [restaurant, setRestaurant] = useState<IRestaurantDetails>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function getRestaurant() {
      const data = await fb
        .firestore()
        .collection("restaurants")
        .where("slug", "==", subdomain)
        .get();

      if (data.docs.length !== 1) {
        setError(true);
      } else {
        const restaurantDoc = data.docs[0];
        console.log(restaurantDoc.data());
        setRestaurant({
          ...data.docs[0].data(),
          id: restaurantDoc.id
        } as IRestaurantDetails);
      }
    }
    getRestaurant();
  }, [subdomain]);

  if (restaurant === undefined) {
    return (
      <Flex align="center" justify="center" w="100vw" h="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return <Text>Couldn't find restaurant :(</Text>;
  }

  const { menu } = restaurant;

  return (
    <RestaurantContextProvider
      value={{
        restaurant,
        menu
      }}
    >
      {children}
    </RestaurantContextProvider>
  );
};

/** Mock Provider for testing/storybook purposes */
const MockRestaurantProvider = RestaurantContextProvider;

export { RestaurantProvider, useRestaurant, MockRestaurantProvider };

