export interface IMenuItem {
  id: string;
  title: string;
  description?: string;
  image: string;
  price: number;
  category: string;
}

export enum OrderStatus {
  PENDING = "Pending",
  PREPARING = "Preparing",
  DELIVERING = "Delivering",
  PICKUP = "Pickup",
  COMPLETE = "Complete",
}

export interface IOrder {
  id: string;
  data: IOrderData;
}

export interface IOrderData {
  type: "Pickup" | "Delivery";
  status: OrderStatus;
  price: number;
  contactNumber: string;
  contactName: string;
  contactEmail: string;
  items: IMenuItemInCart[];
  address?: string;
  restaurantRef: string; // WARNING this is actually FirebaseFirestore.DocumentReference<FirebaseFirestore.DocumentData>
  stripeReference: string;
  createdAt: Date;
  demo?: boolean;
}

export interface IMenuItemInCart extends IMenuItem {
  quantity: number;
}

export interface IRestaurant {
  address: string;
  name: string;
  slug: string;
  logo: string;
  menu: IMenuItem[];
}

export interface IOpeningHours {
  /** the index of day of week (sunday is 0) */
  day: number;
  from: string;
  to: string;
}

export interface IDatedOpeningHours {
  from: Date;
  to: Date;
}

export interface IRestaurantDetails extends IRestaurant {
  id: string;
  contactNumber: string;
  deliverySuburbs: string[];
  openingHours: IOpeningHours[];
  menuCategories: string[];
  preparationTime: number;
  demo?: boolean;
}

export interface IUser {
  name: string;
  email: string;
  address: string;
  phoneNumber: string;
  postCode: string;
  suburb: string;
}

export interface IOrderRequest {
  items: IMenuItemInCart[];
  customer: Partial<IUser>;
  totalOrderValue: number;
  stripeToken?: string;
  restaurantId: string;
}
