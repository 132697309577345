import { Box, Flex, Heading, Stack, Text, FlexProps } from "@chakra-ui/core";
import React from "react";
import { useRestaurant } from "../context/restaurant-context";
import { printTodaysOpeningHours } from "../utils/openingHoursUtils";
import { useOpeningHours } from "../context/opening-hours-context";

export const KarriRestaurantCard: React.FC<FlexProps> = ({ ...props }) => {
  const { restaurant } = useRestaurant();
  const { todaysOpeningHours } = useOpeningHours();

  const openingHoursText =
    printTodaysOpeningHours(todaysOpeningHours) + " today";

  return (
    <Flex h="100%" w="100%" alignItems="space-between" {...props}>
      <Stack spacing={1} p={1} flexGrow={1}>
        <Heading size="sm">{restaurant.name}</Heading>
        <Text fontSize="sm" color="#616161">
          {restaurant.address}
        </Text>
        <Text fontSize="sm" color="#616161">
          {restaurant.contactNumber}
        </Text>

        <Text fontSize="sm" color="#616161">
          {openingHoursText}
        </Text>
      </Stack>
      <Box
        flexShrink={0}
        background={restaurant.logo ? `url("${restaurant.logo}")` : "#dadada"}
        w="80px"
        h="80px"
        backgroundSize="cover"
      />
    </Flex>
  );
};
