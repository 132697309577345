import React from "react";
import { ButtonProps, Button } from "@chakra-ui/core";

/**
 * Default styling for Karri buttons
 */
export const KarriButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => {
    return (
      <Button
        ref={ref}
        fontFamily="Roboto"
        fontWeight={500}
        borderRadius="4px"
        {...props}
      />
    );
  }
);

export const GreyKarriButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => {
    return (
      <KarriButton
        ref={ref}
        backgroundColor="#4F4F4F"
        color="white"
        _hover={{
          backgroundColor: "#3d3f40"
        }}
        {...props}
      />
    );
  }
);

export const GreenKarriButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => {
    return (
      <KarriButton
        ref={ref}
        backgroundColor="#27AE60"
        color="white"
        {...props}
      />
    );
  }
);

export const GhostKarriButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => {
    return (
      <KarriButton
        ref={ref}
        borderColor="#4F4F4F"
        borderWidth="1px"
        variant="ghost"
        color="#4F4F4F"
        // _hover={{
        //   backgroundColor: "#3d3f40"
        // }}
        {...props}
      />
    );
  }
);
