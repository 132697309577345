import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { KarriMenuItemDetail } from "../../components/KarriMenuItemDetail";
import { QuantityButtons } from "../../components/QuantityButtons";
import { useCart } from "../../context/cart-context";
import { useRestaurant } from "../../context/restaurant-context";
import { formatMoney } from "../../utils/formatMoney";
import { useOpeningHours } from "../../context/opening-hours-context";
import { fb } from "../../utils/firebase";
import { analyticsViewItem } from "../../utils/analytics";

const MenuItemDetail: React.FC = () => {
  const { itemId } = useParams();
  const { menu } = useRestaurant();
  const { isOpen } = useOpeningHours();
  const history = useHistory();
  const [quantity, setQuantity] = useState<number>(1);
  const item = menu.find(item => item.id === itemId);
  const { addToCart } = useCart();

  useEffect(() => {
    if (item !== undefined) {
      analyticsViewItem(item);
    }
  }, [item]);

  if (item === undefined)
    return (
      <Box>
        <Text>Could not find this menu item :(</Text>
        <Link to="/">Back to home</Link>
      </Box>
    );

  const handleAddToCart = () => {
    addToCart(item, quantity);
    fb.analytics().logEvent("add_to_cart", {
      items: [{ ...item, quantity }],
      value: item.price * quantity
    });
    history.push("/menu");
  };

  return (
    <Flex flexGrow={1} direction="column">
      <Link to="/menu">
        <IconButton
          icon="arrow-back"
          aria-label="back"
          variant="ghost"
          color="white"
          position="absolute"
          top={1}
          left={1}
          size="lg"
        />
      </Link>
      <KarriMenuItemDetail item={item} />
      <QuantityButtons
        quantity={quantity}
        setQuantity={setQuantity}
        minimum={1}
      />

      <Flex w="100%" p={3} position="absolute" bottom={2}>
        <Button
          onClick={handleAddToCart}
          variantColor="green"
          flexGrow={1}
          display="flex"
          justifyContent="space-between"
          size="lg"
          isDisabled={!isOpen}
        >
          <Text as="span">Add {quantity} to cart</Text>
          <Text as="span">{formatMoney(item.price * quantity)}</Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default MenuItemDetail;
