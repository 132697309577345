import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//  set up stripe with public key
const stripeKey =
  process.env.REACT_APP_STRIPE_API_KEY !== undefined
    ? process.env.REACT_APP_STRIPE_API_KEY
    : "";
const stripePromise = loadStripe(stripeKey);

export const StripeProvider: React.FC = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
