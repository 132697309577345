import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "../context/cart-context";
import { formatMoney } from "../utils/formatMoney";
import { KarriButton } from "./KarriButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;

  /** ref to the button which opened the drawer */
  btnRef?: React.MutableRefObject<HTMLElement | null>;
}

export const KarriCartDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  btnRef
}) => {
  const { hasItemsInCart } = useCart();

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
      size="xl"
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Your cart</DrawerHeader>

        {hasItemsInCart ? <CartItems /> : <CartEmpty onClose={onClose} />}
      </DrawerContent>
    </Drawer>
  );
};

const CartEmpty: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <DrawerBody>There's nothing in your cart.</DrawerBody>

      <DrawerFooter>
        <Flex w="100%" p={3}>
          <Button flexGrow={1} variantColor="green" onClick={onClose}>
            Keep browsing
          </Button>
        </Flex>
      </DrawerFooter>
    </>
  );
};

const CartItems: React.FC = () => {
  const { items } = useCart();
  const history = useHistory();

  return (
    <>
      <DrawerBody>
        <Heading size="sm">Your order</Heading>
        <Stack>
          {items.map((item, i) => (
            <React.Fragment key={item.id}>
              <Link to={`/menu/update/${item.id}`}>
                <Flex direction="row" p={3} align="center">
                  <Flex
                    mr={4}
                    rounded="full"
                    align="center"
                    justify="center"
                    borderColor="gray"
                    borderWidth="1px"
                    // borderRadius="50%"
                    borderStyle="solid"
                    size="32px"
                  >
                    {item.quantity}
                  </Flex>
                  <Text as="span">{item.title}</Text>
                  <Text as="span" ml="auto">
                    {formatMoney(item.price * item.quantity)}
                  </Text>
                </Flex>
              </Link>
              {i < items.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      </DrawerBody>

      <DrawerFooter>
        <Flex w="100%">
          <KarriButton
            flexGrow={1}
            backgroundColor="#27AE60"
            color="white"
            onClick={() => history.push("/menu/checkout/details")}
          >
            Checkout
          </KarriButton>
        </Flex>
      </DrawerFooter>
    </>
  );
};
