import React from "react";
import { Flex, Stack, IconButton, Text } from "@chakra-ui/core";

interface Props {
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  minimum?: number;
}

export const QuantityButtons: React.FC<Props> = ({
  quantity,
  setQuantity,
  minimum
}) => {
  const minQuantity = minimum || 0;
  return (
    <Flex align="center" justify="center">
      <Stack isInline align="center" justify="center">
        <IconButton
          icon="minus"
          aria-label="decrease quantity"
          onClick={() => setQuantity(quantity - 1)}
          isDisabled={quantity === minQuantity}
        />
        <Text aria-label="quantity">{quantity}</Text>
        <IconButton
          icon="add"
          aria-label="increase quantity"
          onClick={() => setQuantity(quantity + 1)}
        />
      </Stack>
    </Flex>
  );
};
