import { Button, Flex, IconButton, Stack, Text } from "@chakra-ui/core";
import React, { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { KarriMenuItemDetail } from "../../components/KarriMenuItemDetail";
import { QuantityButtons } from "../../components/QuantityButtons";
import { useCart } from "../../context/cart-context";
import { formatMoney } from "../../utils/formatMoney";
import { analyticsRemoveFromCart } from "../../utils/analytics";

const MenuItemUpdateCart: React.FC = () => {
  const { itemId } = useParams();
  const { items, updateQuantity } = useCart();
  const item = items.find(item => item.id === itemId);

  const [quantity, setQuantity] = useState<number>(item?.quantity || 0);
  const history = useHistory();

  if (item === undefined) {
    return <Redirect to="/menu?showCart=true" />;
  }

  const handleUpdateCart = () => {
    updateQuantity(item, quantity);
    history.push("/menu?showCart=true");
  };

  const handleRemoveFromCart = () => {
    updateQuantity(item, 0);
    analyticsRemoveFromCart({
      items: [item],
      value: item.price * item.quantity
    });
    history.push("/menu?showCart=true");
  };

  return (
    <Flex flexGrow={1} direction="column" position="relative">
      <Link to="/menu">
        <IconButton
          icon="arrow-back"
          aria-label="back"
          variant="ghost"
          color="white"
          position="absolute"
          top={1}
          left={1}
          size="lg"
        />
      </Link>
      <KarriMenuItemDetail item={item} />
      <QuantityButtons quantity={quantity} setQuantity={setQuantity} />

      <Stack
        w="100%"
        p={3}
        position="absolute"
        bottom={2}
        justify="center"
        spacing={4}
      >
        <Button
          variant="link"
          variantColor="red"
          p={3}
          onClick={handleRemoveFromCart}
        >
          Remove from cart
        </Button>
        <Button
          onClick={handleUpdateCart}
          variantColor="green"
          flexGrow={1}
          display="flex"
          justifyContent="space-between"
          size="lg"
        >
          <Text as="span">Update cart</Text>
          <Text as="span">{formatMoney(item.price * quantity)}</Text>
        </Button>
      </Stack>
    </Flex>
  );
};

export default MenuItemUpdateCart;
