import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from "@chakra-ui/core";
import React from "react";
import { useRestaurant } from "../context/restaurant-context";
import { fb } from "../utils/firebase";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalDeliveryFeatureRequest: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const { restaurant } = useRestaurant();

  const handleFeedback = (feedback: "positive" | "negative") => {
    fb.analytics().logEvent("feature_request", {
      feature: "delivery",
      feedback
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delivery</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>
          <Stack spacing={8}>
            <Text>
              We're still working on adding delivery options to Karri. In the
              meantime, you can call {restaurant.name} at{" "}
              <a
                onClick={() => {
                  fb.analytics().logEvent(
                    "delivery_modal_call_restaurant_link_clicked"
                  );
                }}
                href={"tel:" + restaurant.contactNumber}
                style={{ textDecoration: "underline" }}
              >
                {restaurant.contactNumber}
              </a>{" "}
              to arrange a delivery.
            </Text>
            <Flex direction="column" justify="center">
              <Text textAlign="center" mb={3}>
                Want delivery options in Karri?
              </Text>
              <ButtonGroup justifyContent="center" display="flex">
                <Button onClick={() => handleFeedback("positive")}>
                  <span role="img" aria-label="Thumbs Up">
                    👍
                  </span>
                  Yes, please!
                </Button>
                <Button onClick={() => handleFeedback("negative")}>
                  <span role="img" aria-label="Thumbs Down">
                    👎
                  </span>
                  No thankyou
                </Button>
              </ButtonGroup>
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
