import { Box } from "@chakra-ui/core";
import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import { StripeCardElementOptions } from "@stripe/stripe-js";

const elementStyle: StripeCardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  },
  hidePostalCode: true
};

export const StripeCard: React.FC = () => {
  return (
    <Box py={[8, 4]}>
      <CardElement options={elementStyle} />
    </Box>
  );
};
