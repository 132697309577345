import { Flex, Spinner, useToast } from "@chakra-ui/core";
import React, { useEffect } from "react";
import { DashboardOrderTabBar } from "../../components/DashboardOrderTabBar";
import { useOrders } from "../../context/order-context";
import { OrderDashboardLayout } from "../../layouts/OrderDashboardLayout";
import { DashboardOrderList } from "../../components/DashboardOrderList";
import { DashboardOrderSummary } from "../../components/DashboardOrderSummary";

const OrderDashboard: React.FC = () => {
  const { orders, newOrder } = useOrders();
  const toast = useToast();

  useEffect(() => {
    if (newOrder !== undefined) {
      toast({
        position: "top",
        title: "New Order",
        status: "success",
        duration: 9000,
        isClosable: true
      });
    }
  }, [newOrder, toast]);

  if (orders === undefined) {
    return (
      <Flex h="100vh" w="100vw" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <OrderDashboardLayout>
      <DashboardOrderTabBar />
      <Flex dir="row" flexGrow={1} overflow="hidden">
        <LeftPane>
          <DashboardOrderList />
        </LeftPane>
        <RightPane>
          <DashboardOrderSummary />
        </RightPane>
      </Flex>
    </OrderDashboardLayout>
  );
};

const LeftPane: React.FC = ({ children }) => {
  return (
    <Flex
      borderColor="#E0E0E0"
      borderRightWidth="1px"
      borderStyle="solid"
      w="425px"
      direction="column"
      overflowY="scroll"
    >
      {children}
    </Flex>
  );
};

const RightPane: React.FC = ({ children }) => {
  return (
    <Flex flexGrow={2} flexShrink={0} direction="column" overflow="hidden">
      {children}
    </Flex>
  );
};

export default OrderDashboard;
