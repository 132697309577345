import { Flex, Stack, Text } from "@chakra-ui/core";
import React from "react";
import { useOrders } from "../context/order-context";
import { OrderStatus } from "../types";
import { orderStatusDisplayMap } from "../utils/orderUtils";

export const DashboardOrderTabBar: React.FC = () => {
  return (
    <Flex
      borderBottomColor="#E0E0E0"
      borderBottomWidth="1px"
      borderStyle="solid"
      w="100%"
      align="flex-end"
      h="60px"
      flexShrink={0}
      px={12}
    >
      <Stack isInline spacing={12} shouldWrapChildren>
        {Array.from(orderStatusDisplayMap).map(([key, value]) => (
          <OrderTab
            status={key}
            label={value}
            key={key}
            hideNumber={key === OrderStatus.COMPLETE}
          />
        ))}
      </Stack>
    </Flex>
  );
};

interface OrderTabProps {
  status: OrderStatus;
  label: string;
  hideNumber?: boolean;
}

const OrderTab: React.FC<OrderTabProps> = ({ status, label, hideNumber }) => {
  const { orders, filterStatus, setFilterStatus } = useOrders();

  const numberOfOrders = orders.filter(o => o.data.status === status).length;

  const isActive = status === filterStatus;

  return (
    <Flex
      as="button"
      justify="center"
      align="center"
      pb="7px"
      pl={3}
      onClick={() => setFilterStatus(status)}
      borderBottom={isActive ? "2px solid #2F80ED" : undefined}
    >
      <Text color={isActive ? "#1E2B51" : "#828282"}>{label}</Text>
      <Flex
        opacity={hideNumber === true ? 0 : undefined}
        mb="15px"
        ml="5px"
        align="center"
        justify="center"
        bg={numberOfOrders > 0 ? "#EB5757" : "#BDBDBD"}
        color="white"
        p="2px"
        borderRadius="50%"
        size="24px"
        fontFamily="Roboto Condensed"
        fontWeight="bold"
      >
        {numberOfOrders}
      </Flex>
    </Flex>
  );
};
