import React from "react";
import { IMenuItem } from "../types";
import { Box, Stack, Heading, Text } from "@chakra-ui/core";

interface Props {
  item: IMenuItem;
}

export const KarriMenuItemDetail: React.FC<Props> = ({ item }) => {
  return (
    <Box>
      <Box
        width="100%"
        h="200px"
        background={`linear-gradient(to bottom, rgba(123, 124, 130, 0.52),rgba(255, 255, 255, 0)),url("${item.image}")`}
        backgroundSize="cover"
      />
      <Stack p={3}>
        <Heading>{item.title}</Heading>
        <Text>{item.description}</Text>
      </Stack>
    </Box>
  );
};
